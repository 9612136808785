<script lang="ts">
export default {
  name: 'StoreFront',
};
</script>

<script setup lang="ts">
import Cookie from 'js-cookie';
import {
  inject,
  ref,
  reactive,
  onUnmounted,
  onMounted,
  computed,
  watch,
  provide,
  defineAsyncComponent,
} from 'vue';
import {useRouter, useRoute} from 'vue-router';

import {AppBase, AppSidebar, App, usePwaRefresh} from '@teemill/modules/app';

import {snackbar, useGtm} from '@teemill/common/services';
import {Page, editorInterface, PageLike} from '@teemill/modules/page-framework';
import {ClaimReferralCouponPopup} from '@teemill/modules/referrals';
import {Theme} from '@teemill/modules/theme-builder';
import {ApiError} from '@teemill/common/errors';
import {
  formatUrl,
  PausableRouter,
  updateOrInsertHeadElement,
  minDevice,
  maxDevice,
} from '@teemill/common/helpers';
import {TmlStorage} from '@teemill/common/classes';
import {AppMode} from '@teemill/modules/app';
import {PageProvider} from '@teemill/modules/page-framework';
import {favourites} from '@teemill/common';
import {TmlCookiePopup} from '@teemill/modules/overlays/components/popups';
import {useLiveChat} from '@teemill/modules/live-chat';
import {
  useNotifications,
  NotificationContainer,
  NotificationPosition,
} from '@teemill/modules/notifications';
import {inChristmasPeriod} from '@teemill/utilities';
import type {ChristmasBannerOptions} from '@teemill/modules/plugins';
import {MutationType, usePaymentProcessorStore} from '@teemill/common/vuex';

import * as layouts from './layouts';
import {store} from './services';
import {useShippingStore} from './vuex/shipping';

import {useOffer as useSubscriberOffer} from '@teemill/modules/notifications';

import StoreFrontNestedMenu from './components/StoreFrontNestedMenu.vue';
import StoreFrontHeader from './components/StoreFrontHeader.vue';
import {RemillReturnPopup} from '@teemill/modules/remill';
import FailedToLoad from './components/FailedToLoad.vue';

const failedToLoad = ref(false);

const notifications = useNotifications();

const ChristmasReassuranceBanner = defineAsyncComponent(
  () => import('./components/ChristmasReassuranceBanner.vue')
);
const StoreFrontFooter = defineAsyncComponent(
  () => import('./components/StoreFrontFooter.vue')
);
const StoreFrontSubfooter = defineAsyncComponent(
  () => import('./components/StoreFrontSubfooter.vue')
);
const StoreFrontMinimalFooter = defineAsyncComponent(
  () => import('./components/StoreFrontMinimalFooter.vue')
);
const StoreFrontPasswordProtected = defineAsyncComponent(
  () => import('./components/StoreFrontPasswordProtected.vue')
);

performance.mark('store-front-setup');

const app = ref<App>(App.get('subdomain'));
const router = useRouter() as PausableRouter;
const route = useRoute();
const liveChat = useLiveChat();

const shippingStore = useShippingStore();
const paymentProcessorStore = usePaymentProcessorStore();

router.pause();

usePwaRefresh(router);

const gtm = useGtm();

const setDivision = async (division?: number) => {
  performance.mark('store-front-start-setdivision');

  try {
    await fetch(
      formatUrl(
        `/omnis/v3/frontend/${
          division ? division + '/' : ''
        }getStoreCoreData/json/`
      ),
      {
        credentials: 'include',
        mode: 'no-cors',
      }
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }

        throw new ApiError();
      })
      .then(({pages, state}) => {
        store.commit('currency/install', state.currency);
        store.commit('splitTest/install', state.splitTest);
        store.commit('subdomain/install', state.subdomain);
        shippingStore.mutation(MutationType.shipping.install, {
          christmas: {cutoffDates: state.christmasCutoffDates},
        });
        paymentProcessorStore.mutation('install', state.paymentProcessor);

        store.commit('cart/setItems', state.subdomain.cart.items);

        if (state.subdomain.favicon) {
          updateOrInsertHeadElement('favicon', 'link', {
            rel: 'icon',
            href: formatUrl(state.subdomain.favicon),
          });
        }

        if (state.subdomain.meta?.ogImage) {
          updateOrInsertHeadElement('apple-touch-icon', 'link', {
            rel: 'apple-touch-icon',
            href: formatUrl(state.subdomain.meta.ogImage),
          });
        }

        if (state.subdomain.customCode) {
          const documentFragment = document
            .createRange()
            .createContextualFragment(state.subdomain.customCode);
          document.head.appendChild(documentFragment);
        }

        if (state.subdomain.favourites) {
          favourites.setItems([...state.subdomain.favourites.items]);
          favourites.applySplitTestVariation();
        }

        let shouldShowCurrencyDiscountMessage = true;

        watch(
          () => route,
          to => {
            store.dispatch('updateDocumentTitle', to);

            if (
              to.name === 'Basket' &&
              shouldShowCurrencyDiscountMessage &&
              store.getters['cart/getQuantity'] &&
              store.getters['currency/get']().code !==
                (store?.state?.subdomain?.currency ?? 'GBP')
            ) {
              shouldShowCurrencyDiscountMessage = false;

              setTimeout(() => {
                snackbar(
                  'Discount applied due to improved exchange rates',
                  false,
                  10000
                );
              }, 500);
            }
          },
          {immediate: true, deep: true}
        );

        app.value.theme = new Theme(state.theme.theme);

        app.value.pages.forEach(page =>
          router.removeRoute(page.routeDef.name || 'home')
        );

        pages.forEach((rawPage: PageLike) => {
          const page = reactive(new Page(rawPage)).setMode(app.value.mode);

          app.value.addPage(page);
          router.addRoute(page.routeDef);
        });

        if (app.value.isNative) {
          router.resume();
        }

        // We need to wait for the router to be ready before
        // we can access querystring properties on the route
        // object, or they will come back as "undefined"
        router.isReady().then(() => {
          const offerData = state.subdomain?.plugin['email-subscriber-offer'];
          const offerEnabled = offerData?.enabled === true;
          const cgiCookie = Cookie.get('cgi');
          const utmSource = route.query.utm_source_platform;

          if (
            offerEnabled &&
            app.value.isNative &&
            // We want to check the user origin here and
            // only show the prompt if they *did not* come
            // from a teemail origin
            cgiCookie !== 'teemail' &&
            utmSource !== 'teemail'
          ) {
            useSubscriberOffer().show({
              ...offerData,
              division: state.subdomain?.division,
            });
          }

          liveChat.init();
        });

        if (TmlStorage.local.get('cookies-accepted')) {
          window.gtag('consent', 'update', {
            ad_user_data: 'granted',
            ad_personalization: 'granted',
            ad_storage: 'granted',
            analytics_storage: 'granted',
          });
        }

        setTimeout(() => {
          gtm.enable();
        }, 5000);
      });
  } catch (err) {
    setTimeout(() => {
      failedToLoad.value = true;
    }, 1000);
  }

  performance.mark('store-front-end-setdivision');
  performance.measure(
    'Store Front setDivision',
    'store-front-start-setdivision',
    'store-front-end-setdivision'
  );
};

const {uninstall, ready, onConnect} = editorInterface({app: app.value as App});

/**
 * This will disable actions such as clickable header and footer.
 */
app.value.setMode(inject<AppMode>('app-mode', 'native'));

onConnect(
  async ({division: divisionId, page: pageId, layout, compatibility}) => {
    await setDivision(divisionId);

    let page: Page;

    if (pageId) {
      page = app.value.addPage(await PageProvider.get(pageId, divisionId));
    } else {
      page = app.value.addPage(
        new Page({
          id: undefined,
          mode: app.value.mode,
          layout,
          blocks: [],
          properties: {},
          preloadables: [],
          published: true,
          sitemap: true,
        })
      );
    }

    if (page.url === undefined) {
      page.url = `/${page.temporaryUrl}/`;
    }

    router.addRoute(page.routeDef);

    if (page.url !== undefined) {
      router.push(page.url);
      router.resume();
    }

    Array.from(compatibility).forEach(flag => {
      const type = flag === 'page' ? 'Store' : 'Mail';

      if (flag === 'mail') {
        page.sitemap = false;
      }

      page.setProperty(`is${type}Page`, 1);
    });

    if (compatibility.has('flow')) {
      page.setProperty('isAutomatedFlow', 1);
    }

    if (compatibility.has('mail')) {
      page.layout = 'mail';
    }

    return page;
  }
);

// Compatibility with sub-components in dashboard. Required for
// Cloud iframe project. Can be removed once website migration
// to Cloud complete.
const operatorMode = ref('default');

provide('operatorMode', operatorMode);

onMounted(() => {
  ready();
});

onUnmounted(() => {
  uninstall();
});

if (app.value.mode === 'native') {
  /**
   * Add the division ID to the store core data request URL if it's availble on the window object.
   * This ensures the URL path is unique for each store and reduces the chance of search engines caching the response between stores.
   */
  setDivision(window.division_key ? Number(window.division_key) : undefined);
}

/**
 * Makes header unclickable when app is not in native mode
 * (Connected to the editor)
 */
const noClickStyles = computed(() => ({
  pointerEvents: app.value.mode !== 'native' ? 'none' : undefined,
}));

const enforcedCookieBannerOrigins = ['5'];

const showCookiePopup = computed(() => {
  const cgiValue =
    router.currentRoute.value?.query?.cgi ??
    router.currentRoute.value?.query?.CGI;

  // The cookie banner should always appear for certain origins
  if (
    typeof cgiValue === 'string' &&
    enforcedCookieBannerOrigins.includes(cgiValue)
  ) {
    return true;
  }

  const cookiePolicyPlugin =
    store.getters['subdomain/getPlugin']('cookie-policy');

  return !cookiePolicyPlugin || cookiePolicyPlugin.enabled;
});

const christmasBannerPlugin = computed((): ChristmasBannerOptions => {
  const plugin = store.state.subdomain?.plugin['christmas-banner'] as
    | ChristmasBannerOptions
    | undefined;

  if (plugin === undefined) {
    return {
      disabled: false,
      backgroundColor: '#9E1919',
      textColor: '#ffffff',
    };
  }

  return plugin;
});

const onConsentGranted = () => {
  gtm.trackEvent({event: 'onConsentGranted'});
};
</script>

<template>
  <failed-to-load v-if="failedToLoad" />
  <app-base v-if="app && !app.theme.name" :app="app as App" :layouts="layouts">
    <template #announcement>
      <store-front-password-protected
        v-if="app.isNative && store.state.subdomain.passwordProtected"
      />
      <christmas-reassurance-banner
        v-if="
          inChristmasPeriod(new Date()) &&
          shippingStore.state.christmas.cutoffDates &&
          route.meta.layout !== 'studio' &&
          !christmasBannerPlugin.disabled
        "
        v-bind="{
          ...christmasBannerPlugin,
          cutoffDates: shippingStore.state.christmas.cutoffDates,
        }"
      />
    </template>
    <template #header="{type}">
      <store-front-header :no-click-styles="noClickStyles" />
      <div class="w-full relative container">
        <notification-container
          v-if="app.isNative"
          class="z-notification"
          :queue="notifications.positions.value[NotificationPosition.TOPRIGHT]"
          :style="{
            position: 'absolute',
            right: maxDevice('sm') ? '0.5rem' : '1rem',
            top: maxDevice('sm') ? '0.5rem' : '1rem',
          }"
        />
      </div>
    </template>
    <template #sidebar="{type, open}">
      <app-sidebar class="p-4">
        <div class="mt-5">
          <store-front-nested-menu direction="column" :open="open" />
        </div>
      </app-sidebar>
    </template>
    <template #before-content>
      <tml-breadcrumb
        v-if="$route.meta.breadcrumb"
        class="hidden md:flex py-4"
      />
    </template>
    <template #content>
      <router-view v-slot="{Component}">
        <keep-alive include="CollectionPage" :max="1">
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </template>
    <template #after-content />
    <template #footer>
      <store-front-footer
        v-if="app.theme.get('footer.visible') === '1'"
        :style="{...noClickStyles}"
      />
    </template>
    <template #subfooter>
      <store-front-subfooter
        v-if="app.theme.get('footer.visible') === '1'"
        :style="{...noClickStyles}"
      />
      <store-front-minimal-footer v-else :style="{...noClickStyles}" />
    </template>
    <template #global>
      <tml-snackbar-container />
      <claim-referral-coupon-popup />
      <tml-cookie-popup
        v-if="app.isNative && showCookiePopup"
        :message="
          store.getters['subdomain/getPlugin']('cookie-policy')?.message
        "
        :allow-essential-only="
          store.getters['subdomain/getPlugin']('cookie-policy')
            ?.allowEssentialOnly
        "
        @consent-granted="onConsentGranted"
      />
      <remill-return-popup />
    </template>
  </app-base>
</template>

<style lang="scss">
@import '@teemill/common/assets/scss/core.scss';
</style>
