import {
  useNotifications,
  NotificationPosition,
} from '@teemill/modules/notifications';

export interface AddToBagData {
  name: string;
  price: number;
  salePrice?: number;
  image: string;
  options: [
    {
      color?: string;
      size: string;
    }
  ];
  bagCount: number;
}

const {create, setRouter} = useNotifications();

const show = ({
  name,
  price,
  salePrice,
  image,
  options,
  bagCount,
}: AddToBagData) => {
  create({
    id: 'add-to-bag',
    title: 'Added to bag.',
    body: '',
    image: image,
    position: NotificationPosition.TOPRIGHT,
    template: 'Bag',
    data: {
      name,
      price,
      salePrice,
      options,
    },
    actions: [
      {
        text: `View Bag (${bagCount})`,
        href: 'action://plugins/add-to-bag/go-to-bag',
        primary: false,
      },
      {
        text: 'Checkout',
        href: 'action://plugins/add-to-bag/go-to-checkout',
        primary: true,
      },
    ],
    dismissAction: {
      href: 'action://plugins/add-to-bag/dismiss',
    },
    footerAction: {
      text: 'Continue Shopping',
      href: 'action://plugins/add-to-bag/dismiss',
    },
  });
};

export function useAddToBag() {
  return {
    show,
    setRouter,
  };
}
