import {http, useGtm} from '@teemill/common/services';
import {formatUrl} from '@teemill/common/helpers';
import {useNotifications, useAddToBag} from '@teemill/modules/notifications';
import {Store} from 'vuex';
import {Router} from 'vue-router';

interface CartItem {
  key: string;
  quantity: number;
  name: string;
  priceIncTax: number;
  image: string;
  options: {
    Colour: string;
    Size: string;
  }[];
  productUuid: string;
}

export const onReceiveAddToCartMessage = async (
  event: MessageEvent,
  store: Store<any>,
  router: Router
) => {
  if (typeof event.data.data.variantId === 'string') {
    const existingCartItems = store.getters['cart/getItems'];

    const response = await store.dispatch('cart/addItem', {
      item: {
        variantId: event.data.data.variantId,
      },
      name: 'main',
    });

    sendReplyMessage(
      event,
      Array.isArray(response) && !response.length ? 'failed' : 'success'
    );

    afterAddToCart(existingCartItems);
  }

  function sendReplyMessage(event: MessageEvent, status: 'failed' | 'success') {
    Array.from(document.getElementsByTagName('iframe')).forEach(iframe => {
      let iframeOrigin = null;

      try {
        iframeOrigin = new URL(iframe.src).origin;
      } catch (e) {
        return;
      }

      if (event.origin === iframeOrigin && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          {
            name:
              status === 'success'
                ? 'add-to-cart-complete'
                : 'add-to-cart-failed',
          },
          event.origin
        );
      }
    });
  }

  function afterAddToCart(
    existingCartItems: {
      key: string;
      quantity: number;
    }[]
  ) {
    http
      .get(formatUrl('/omnis/v3/basket/main/list/'))
      .success((data: {items: CartItem[]}) => {
        const addedItem = data.items.find(
          item =>
            !existingCartItems.find(
              i => i.key === item.key && i.quantity === item.quantity
            )
        );

        if (!addedItem) {
          return;
        }

        showAddedToBagNotification(addedItem);

        sendAddToCartEvent(addedItem);
      });
  }

  function showAddedToBagNotification(product: CartItem) {
    const {handle, notifications, removeAll} = useNotifications();

    const subscribeOfferNotification = notifications.find(
      notification => notification.id === 'subscriber-offer-discount'
    );

    if (subscribeOfferNotification) {
      handle(subscribeOfferNotification, {
        href: 'action://plugins/subscriber-offer/dismiss',
      });
    }

    removeAll();

    const {setRouter, show} = useAddToBag();
    setRouter(router);
    show({
      name: product.name,
      price: product.priceIncTax,
      image: product.image,
      options: [
        {
          color: product.options[0].Colour,
          size: product.options[0].Size,
        },
      ],
      bagCount: store.getters['cart/getQuantity'],
    });
  }

  function sendAddToCartEvent(product: CartItem) {
    useGtm().addToCart({
      contentType: 'product',
      contentIds: [product.productUuid],
      items: [
        {
          id: product.productUuid,
          name: product.name,
          brand: JSON.stringify(store.state.subdomain.company.name),
          price: product.priceIncTax,
          currency: store?.state?.subdomain?.currency ?? 'GBP',
          dimension4: `teemill:${store.state.subdomain.divisionName}`,
          quantity: 1,
          options: [
            {
              color: product.options[0].Colour,
              size: product.options[0].Size,
            },
          ],
        },
      ],
      currency: store?.state?.subdomain?.currency ?? 'GBP',
      value: product.priceIncTax,
      dimension4: `teemill:${store.state.subdomain.divisionName}`,
      userId: store.state.subdomain.sessionId,
    });
  }
};
