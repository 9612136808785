<template>
  <div
    class="tml-image-zoom zoom-on-hover"
    @mousemove="move"
    @mouseenter="() => clickToZoom ? null : zoom()"
    @mouseup="() => clickToZoom ? toggleZoom() : null"
    @mouseleave="unzoom"
  >
    <div ref="normal">
      <slot name="default">
        <tml-image
          class="h-full normal"
          :src="imgNormal"
          :alt="alt"
          :style="imgNormalStyle"
          :lazy-load="lazy"
          :src-set="[]"
        />
      </slot>
    </div>
    <div ref="zoom">
      <slot name="zoom">
        <tml-image
          class="zoom"
          max-width="initial"
          :src="imgZoom || imgNormal"
          :alt="alt"
          :style="imgZoomStyle"
          :lazy-load="lazy"
          :src-set="[]"
          @loaded="onImageLoaded"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import {splitTests} from '@teemill/common/classes';
import TmlImage from '../images/TmlImage.vue';

function pageOffset(el) {
  // -> {x: number, y: number}
  // get the left and top offset of a dom block element
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  return {
    y: rect.top + scrollTop,
    x: rect.left + scrollLeft,
  };
}

export default {
  components: {TmlImage},

  props: {
    imgNormal: String,
    imgZoom: String,
    disabled: Boolean,
    alt: String,
    lazy: Boolean,
  },

  data: () => ({
    hasMoved: false,

    zoomMode: false,

    imgNormalOpacity: 1,
    imgZoomOpacity: 0,

    imgZoomLeft: 0,
    imgZoomTop: 0,

    imgNaturalWidth: 0,
    imgNaturalHeight: 0,

    hasLoaded: false,
  }),

  computed: {
    clickToZoom() {
      return splitTests.isActiveVariation('product_page_refresh', 'after');
    },

    imgNormalStyle() {
      return {
        opacity: this.imgNormalOpacity,
      };
    },

    imgZoomStyle() {
      return {
        opacity: this.imgZoomOpacity,
        left: this.imgZoomLeft,
        top: this.imgZoomTop,
        width: `${this.imgNaturalWidth}px`,
      };
    },
  },

  methods: {
    onImageLoaded(e) {
      this.imgNaturalWidth = e.naturalWidth;
      this.imgNaturalHeight = e.naturalHeight;
    },

    toggleZoom() {
      if (this.zoomMode) {
        this.unzoom();
      } else {
        this.zoom();
        this.move();
      }
    },

    zoom() {
      if (this.disabled) {
        return;
      }

      this.hasMoved = false;
      this.zoomMode = true;
    },

    unzoom() {
      if (this.$refs.zoom && this.$refs.normal) {
        if (this.disabled) {
          return;
        }

        this.imgZoomOpacity = 0;
        this.imgNormalOpacity = 1;

        this.hasMoved = false;
        this.zoomMode = false;
      }
    },

    move(event) {
      if (this.$refs.zoom && this.$refs.normal) {
        if (this.disabled || (this.clickToZoom && !this.zoomMode)) {
          return;
        }

        if (!this.hasMoved) {
          this.hasMoved = true;
          this.imgZoomOpacity = 1;
          this.imgNormalOpacity = 0;
        }

        const offset = pageOffset(this.$el);
        let normal = this.$refs.normal;

        if (normal.$el) {
          normal = normal.$el;
        }

        const relativeX = event.clientX - offset.x + window.pageXOffset;
        const relativeY = event.clientY - offset.y + window.pageYOffset;

        const normalFactorX = relativeX / normal.offsetWidth;
        const normalFactorY = relativeY / normal.offsetHeight;

        const x = normalFactorX * (this.imgNaturalWidth - normal.offsetWidth);
        const y = normalFactorY * (this.imgNaturalHeight - normal.offsetHeight);

        this.imgZoomLeft = `${-x}px`;
        this.imgZoomTop = `${-y}px`;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tml-image-zoom {
  position: relative;
  overflow: hidden;

  .normal {
    width: 100%;
  }

  .zoom {
    position: absolute;
    opacity: 0;
    transform-origin: top left;
  }
}
</style>
