import {formatUrl} from '@teemill/common/helpers';

export default {
  async addItem({commit}, item) {
    let response = [];
    await $axios
      .post(formatUrl('/omnis/v3/frontend/product/addItemToCart/'), item)
      .success(data => {
        commit('setItems', data.items);

        item = item?.item;

        response = data;
      })
      .oops('Oops... Looks like this product is no longer available');

    return response;
  },

  async removeItem({commit}, key) {
    let response = [];
    await $axios
      .post(formatUrl('/omnis/v3/basket/remove/'), key)
      .success(data => {
        response = data;
        commit('setItems', response.items);
      });
    return response;
  },

  async updateItem({commit}, itemData) {
    let response = [];
    await $axios
      .post(formatUrl('/omnis/v3/basket/update/'), itemData)
      .success(data => {
        response = data;
        commit('setItems', response.items);
      });

    return response;
  },

  async bulkAddItems({commit}, {items, name}) {
    let response = [];

    await $axios
      .post(formatUrl('/omnis/v3/frontend/product/bulkAddItemsToCart/'), {
        items,
        name,
      })
      .success(data => {
        commit('setItems', data.items);

        response = data;
      })
      .oops('Oops... Looks like this product is no longer available');

    return response;
  },
};
